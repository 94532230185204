const routes = {
  home: "/",
  basket: "/koszyk",
  order: "/zamowienie/:orderId",
  paymentProcessing: "/przetwarzanie-platnosci/:paymentId",
  termsOfService: "/regulamin",
  privacyPolicy: "/polityka-prywatnosci",
};

const config = {
  app: {
    rootUrl: location.protocol + "//" + location.host,
  },
  company: {
    name: "BMS - Wypożyczalnia Rowrów",
    city: "Świeradów-Zdrój",
    zipCode: "59-850",
    street: "Traugutta 17",
  },
  contact: {
    phone: "517 416 110",
    email: "swieradow@bmsrent.pl",
  },
  przelewy24: {
    urlReturn: routes.paymentProcessing,
  },

  footer: {
    socialMedia: [
      {
        url: "https://www.facebook.com/bmstrek",
        icon: "bi-facebook",
      },
      {
        url: "https://www.instagram.com/wypozyczalnia_bms/",
        icon: "bi-instagram",
      },
    ],
    links: [
      {
        url: routes.termsOfService,
        text: "Regulamin",
      },
      {
        url: routes.privacyPolicy,
        text: "Polityka Prywatności",
      },
      {
        url: "https://bmsrent.pl",
        text: "Nasze wypożyczalnie",
      },
      {
        url: "https://bmsbike.com",
        text: "E-sklep",
      },
    ],
  },
};

export { routes };
export default config;
