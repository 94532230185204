import "./LayoutFooter.css";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import config, { routes } from "../../../Config";
import TitleWidget from "../../common/title-widget/TitleWidget";
import { Link } from "react-router-dom";

const LayoutFooter = () => {
  const renderLinks = () => {
    if (config.footer.links === null || config.footer.links.length === 0)
      return null;
    return (
      <div className="menu-widget">
        <ul>
          {config.footer.links.map((item, index) => (
            <li key={index} className="menu-item ">
              {item.url.startsWith("/") ? (
                <Link to={item.url}>{item.text}</Link>
              ) : (
                <a href={item.url}>{item.text}</a>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderSocialMedia = () => {
    if (
      config.footer.socialMedia === null ||
      config.footer.socialMedia.length === 0
    )
      return null;
    return (
      <div className="menu-inline">
        <ul>
          {config.footer.socialMedia.map((item, index) => (
            <li key={index} className="menu-item ">
              <a href={item.url}>
                <i className={item.icon}></i>
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  return (
    <footer className="text-white py-5">
      <Container>
        <Row>
          <Col sm={6} lg={4}>
            <p>
              Rządź na rowerze w Izerach! - lekkie rowery MTB i elektryczne MTB
              dla amatorów oraz zaawansowanych bikerów, dla rodzin z dziećmi.
            </p>

            <div>
              <ul className="contact-info">
                <li>
                  <i className="bi bi-geo-alt-fill"></i>
                  {config.company.street}
                  <br />
                  {config.company.zipCode} {config.company.city}
                </li>
                <li>
                  <i className="bi bi-phone-fill"></i>
                  {config.contact.phone}
                </li>
                <li>
                  <i className="bi bi-envelope-fill"></i>
                  {config.contact.email}
                </li>
              </ul>
            </div>
          </Col>
          <Col sm={6} lg={4}>
            <TitleWidget>Linki</TitleWidget>

            {renderLinks()}

            <TitleWidget>Social Media</TitleWidget>
            {renderSocialMedia()}
          </Col>

          <Col md={6} lg={4}>
            <TitleWidget>Godziny pracy</TitleWidget>
            <table className="working-hours">
              <tbody>
                <tr>
                  <th>Poniedziałek</th>
                  <td>9:00 - 17:00</td>
                </tr>
                <tr>
                  <th>Wtorek</th>
                  <td>9:00 - 17:00</td>
                </tr>
                <tr>
                  <th>Środa</th>
                  <td>9:00 - 17:00</td>
                </tr>
                <tr>
                  <th>Czwartek</th>
                  <td>9:00 - 17:00</td>
                </tr>
                <tr>
                  <th>Piątek</th>
                  <td>9:00 - 17:00</td>
                </tr>
                <tr>
                  <th>Sobota</th>
                  <td>9:00 - 17:00</td>
                </tr>
                <tr>
                  <th>Niedziela</th>
                  <td>9:00 - 17:00</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default LayoutFooter;
