import React from "react";

const TermsOfServicePage = () => {
  return (
    <div className="container  mb-3">
      <div className="row justify-content-center">
        <div className="col-12 col-xl-8">
          <h1 className="mt-5 mb-3 fs-4">REGULAMIN WYPOŻYCZALNI ROWERÓW BMS W ŚWIERADOWIE ZDROJU</h1>

          <div className="section">
            <h2 className="text-center fs-5">§ 1</h2>
            <p className="text-justify">
              Niniejszy Regulamin określa warunki oraz zasady korzystania z
              rowerów, rowerów elektrycznych, sprzętu i wyposażenia
              turystycznego (zwanych w dalszej części: „sprzętem”), dla klientów
              indywidualnych i instytucjonalnych korzystających z wypożyczalni
              rowerów BMS (dalej: „BMS” lub „Wypożyczający”) w Świeradowie-
              Zdroju.
            </p>
          </div>

          <div className="section">
            <h2 className="text-center fs-5">§ 2</h2>
            <h3 className="fs-5 fw-bold">Definicje</h3>
            <p className="text-justify">
              <b>1. Regulamin</b> - niniejszy Regulamin opisujący zasady i
              warunki korzystania, w szczególności warunki, zakres praw i
              obowiązków oraz odpowiedzialność osób korzystających z
              wypożyczania rowerów w wypożyczalni „BMS”. Akceptacja postanowień
              Regulaminu i spełnienie wszystkich warunków w nim określonych
              stanowi podstawę i warunek zezwolenia na wypożyczenie roweru w
              wypożyczalni „BMS”.
            </p>
            <p className="text-justify">
              <b>2. Najemca</b> – pełnoletnia osoba fizyczna lub osoba prawna,
              będąca stroną Umowy najmu przez Wynajmującego i/lub korzystająca z
              pakietów przygotowanych przez Wynajmującego, obejmujących usługi
              świadczone przez wypożyczalnię „BMS”.
            </p>
            <p className="text-justify">
              <b>3. Wynajmujący</b> – Marek Gąsior, prowadzący działalność
              gospodarczą pod nazwą Sklep Rowerowy „Bike Multi Sport” Marek
              Gąsior, ul. Rzemieślnicza 6, 72-100 Goleniów, NIP 856-102-57-34
            </p>
            <p className="text-justify">
              <b>4. Rower elektryczny</b> - rower posiadający dodatkowe
              wspomaganie, zasilany silnikiem elektrycznym z akumulatora,
              wynajmowany Najemcy w ramach Umowy najmu.
            </p>
            <p className="text-justify">
              <b>5. Usługa najmu</b> – wynajem sprzętu na warunkach określonych
              w Umowie najmu. Umowa reguluje w szczególności liczbę i typ
              rowerów, czas najmu (datę wynajmu i godzinę zwrotu), liczbę dni,
              miejsce wynajmu i zwrotu, jak również wynajem dodatkowych
              elementów sprzętu i wyposażenia oraz ewentualne świadczenia
              dodatkowe. Usługa najmu obejmuje również przekazanie Najemcy przez
              Wynajmującego ładowarki do baterii.
            </p>
          </div>

          <div className="section">
            <h2 className="text-center fs-5">§ 3</h2>
            <p className="text-justify">
              Wypożyczalnia rowerów czynna jest od poniedziałku do niedzieli w
              godzinach od 09:00 – 17:00.
            </p>
          </div>

          <div className="section">
            <h2 className="text-center fs-5">§ 4</h2>
            <p className="text-justify">
              Wypożyczenie roweru i złożenie podpisu na Umowie najmu potwierdza,
              że wypożyczający zapoznał się z treścią niniejszego Regulaminu i
              go akceptuje.
            </p>
          </div>

          <div className="section">
            <h2 className="text-center fs-5">§ 5</h2>
            <p className="text-justify">
              Wypożyczane rowery są własnością Marka Gąsiora, prowadzącego
              działalność gospodarczą pod nazwą Sklep Rowerowy „Bike Multi
              Sport” Marek Gąsior, ul. Rzemieślnicza 6, 72-100 Goleniów, NIP
              856-102-57-34
            </p>
          </div>

          <div className="section">
            <h2 className="text-center fs-5">§ 7</h2>
            <p className="text-justify">
              Wypożyczane rowery są sprawne technicznie, mogą nosić ślady
              wcześniejszego użytkowania.
            </p>
          </div>

          <div className="section">
            <h2 className="text-center fs-5">§ 8</h2>
            <p className="text-justify">
              Wypożyczenie sprzętu następuje po przedstawieniu przez najemcę
              dwóch dokumentów potwierdzających tożsamość, wniesieniu opłaty za
              najem sprzętu zgodnie z cennikiem wypożyczalni oraz kaucji.
              Szczegółowe warunki najmu określa Umowa najmu.
            </p>
          </div>

          <div className="section">
            <h2 className="text-center fs-5">§ 9</h2>
            <p className="text-justify">
              Wypożyczenie sprzętu następuje bezpośrednio w miejscu
              funkcjonowania wypożyczalni, tj. wypożyczalnia BMS, 59-850
              Świeradów- Zdrój, ul. Traugutta 17.
            </p>
          </div>

          <div className="section">
            <h2 className="text-center fs-5">§ 10</h2>
            <p className="text-justify">
              1. Najemca wynajmuje rower wyłącznie po dokonaniu rezerwacji za
              pośrednictwem strony internetowej swieradow.bmsrent.pl
            </p>
            <p className="text-justify">
              2. Na poczet rezerwacji Wynajmujący pobiera bezzwrotną przedpłatę
              w wysokości 50% całkowitego kosztu wypożyczenia.
            </p>
            <p className="text-justify">
              3. Bezzwrotna przedpłata stanowi część zapłaty Najemcy dla
              Wynajmującego za wypożyczony sprzęt.
            </p>
            <p className="text-justify">
              4. W przypadku nieodebrania sprzętu w pierwszym dniu rezerwacji do
              godziny 13.00 – wpłacona bezzwrotna przedpłata przepada na rzecz
              Wynajmującego.
            </p>
            <p className="text-justify">
              5. Wynajmujący umożliwia dostęp do strony internetowej bmsbike.com
              w celu dokonania rezerwacji sprzętu w wypożyczalni.
            </p>
          </div>

          <div className="section">
            <h2 className="text-center fs-5">§ 11</h2>
            <p className="text-justify">
              1. W momencie objęcia rezerwowanego roweru w najem Najemca wpłaca
              kaucję w wysokości 500 zł. tytułem zabezpieczenia najmu sprzętu.
            </p>
            <p className="text-justify">
              2. Najemca wpłaca kaucję wyłącznie własną kartą płatniczą w
              siedzibie wypożyczalni.
            </p>
            <p className="text-justify">
              3. Kaucja podlega rozliczeniu (zwrotowi) niezwłocznie po zwrocie
              sprzętu przez Najemcę (wypłata gotówki przez Wynajmującego).
            </p>
          </div>

          <div className="section">
            <h2 className="text-center fs-5">§ 12</h2>
            <p className="text-justify">
              Wynajmujący może odmówić wypożyczenia sprzętu w szczególności,
              gdy:
            </p>
            <p className="text-justify">
              1. osoba chcąca wynająć sprzęt nie dokonała rezerwacji poprzez
              stronę internetową bmsrent.pl
            </p>
            <p className="text-justify">
              2. osoba chcąca wynająć sprzęt znajduje się w stanie
              nietrzeźwości, w stanie wskazującym na spożycie alkoholu, w stanie
              wskazującym na spożycie środków odurzających, substancji
              psychotropowych lub innych tego typu substancji oddziałujących na
              centralny układ nerwowy.
            </p>
            <p className="text-justify">
              3. osoba chcąca wynająć sprzęt nie przedstawi dwóch dokumentów
              potwierdzających tożsamość
            </p>
            <p className="text-justify">4. osoba chcąca wynająć sprzęt nie wpłaci kaucji</p>
            <p className="text-justify">5. osoba chcąca wynająć sprzęt odmówi podpisania Umowy najmu.</p>
          </div>

          <div className="section">
            <h2 className="text-center fs-5">§ 13</h2>
            <p className="text-justify">
              1. Czas wypożyczenia sprzętu liczony jest w dniach, przy czym za
              dzień najmu liczy się godziny funkcjonowania wypożyczalni „BMS”,
              tj. poniedziałek - niedziela: w godzinach 9.00 – 17.00 i jest
              niezależny od rzeczywistej ilości godzin wypożyczenia sprzętu
            </p>
            <p className="text-justify">2. Dzień najmu nie stanowi doby kalendarzowej.</p>
            <p className="text-justify">
              3. Zwrot sprzętu następuje w godzinach funkcjonowania Wypożyczalni
              „BMS”, zgodnie z deklaracją najemcy ujawnioną w Umowie najmu.
            </p>
          </div>

          <div className="section">
            <h2 className="text-center fs-5">§ 14</h2>
            <p className="text-justify">Niniejszy regulamin obowiązuje od 1 sierpnia 2024 r.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfServicePage;
