import React from "react";
const PrivacyPolicyPage = () => {
  return (
    <div className="container mb-3">
      <div className="row justify-content-center">
        <div className="col-12 col-xl-8">
          <h1 className="mt-5 mb-3 fs-4">
            POLITYKA PRYWATNOŚCI W FIRMIE BIKE MULTI SPORT MAREK GĄSIOR
          </h1>

          <p className="text-justify">Cześć Bajkerko! Cześć Bajkerze!</p>
          <p className="text-justify">
            Jeżeli korzystasz ze stron internetowych:{" "}
            <a href="http://bmsbike.com/">http://bmsbike.com/</a>,{" "}
            <a href="http://bmsrent.pl/">http://bmsrent.pl/</a> („Strony
            Internetowe BMS”) zachęcamy gorąco, abyś zapoznał się z naszą
            Polityką Prywatności (PP).
          </p>
          <p className="text-justify">
            Gdy przeczytasz PP, zyskasz pewność, że Twoje dane osobowe, które
            nam powierzasz zbieramy, przechowujemy i używamy z niezwykłą
            starannością i troską. Przestaniesz zastanawiać się: dlaczego i do
            czego ich używamy. Zamiast łamać sobie głowę, w jaki sposób je
            zabezpieczamy, zaczniesz spokojnie kręcić kilometry. Nasze
            obowiązki, Twoje prawa oraz zagadnienia związane z plikami cookies
            będą dla Ciebie jasne, jak blask lakieru nowej ramy.
          </p>

          <h2 className="fs-5">I. Kto administruje Twoje dane osobowe?</h2>
          <ol>
            <li>
              Administratorem strony bmsbike.com oraz administratorem Twoich
              danych osobowych jest Marek Gąsior prowadzący działalność
              gospodarczą pod firmą Bike Multi Sport Marek Gąsior, ul.
              Rzemieślnicza 6, 72-100 Goleniów, REGON 812576007, NIP 8561025734,
              wpisaną do Centralnej Ewidencji i Informacji o Działalności
              Gospodarczej.
            </li>
            <li>
              Jeżeli masz jakiekolwiek wątpliwości i pytania dotyczące zasad
              ochrony Twoich danych osobowych w naszej firmie, napisz na nasz
              adres mailowy lub zadzwoń.
            </li>
          </ol>

          <h2 className="fs-5">
            II. W jaki sposób zbieramy Twoje dane osobowe?
          </h2>
          <ol>
            <li>
              Automatycznie: gdy odwiedzasz Strony Internetowe BMS,
              automatycznie zbierane są Twoje dane informatyczne dotyczące
              Twoich odwiedzin, w szczególności:
              <ul>
                <li>adres IP,</li>
                <li>rodzaj systemu operacyjnego,</li>
                <li>rodzaj/typ przeglądarki.</li>
              </ul>
            </li>
            <li>
              Dobrowolnie przekazujesz nam swoje dane osobowe, gdy:
              <ul>
                <li>zapisujesz się do Newslettera,</li>
                <li>składasz zamówienie,</li>
                <li>rezerwujesz, wypożyczasz rower lub akcesoria rowerowe,</li>
                <li>
                  kontaktujesz się z nami w sprawie swojego zamówienia,
                  rezerwacji lub innej związanej z działalnością Stron
                  Internetowych BMS.
                </li>
              </ul>
            </li>
            <li>
              Dane osobowe możesz nam przekazać za pomocą telefonu, poczty
              e-mail, faksu, formularzy, itp. Zbierzemy od Ciebie wyłącznie te
              dane, które są konieczne do prawidłowego wykonania świadczonej
              przez nas usługi:
              <ul>
                <li>imię i nazwisko,</li>
                <li>adres e-mail,</li>
                <li>nr telefonu,</li>
                <li>adres zamieszkania,</li>
                <li>
                  nr PESEL (wyłącznie w przypadku wypożyczania przez Ciebie
                  roweru) numery dwóch dowodów tożsamości (wyłącznie w przypadku
                  wypożyczania przez Ciebie roweru),
                </li>
                <li>
                  przedział wagowy (wyłącznie podczas rezerwacji roweru na{" "}
                  <a href="http://bmsrent.pl">http://bmsrent.pl</a>),
                </li>
                <li>
                  dodatkowe dane: informacje o prowadzonej działalności
                  gospodarczej, jeżeli chcesz otrzymać fakturę w związku z
                  prowadzoną działalnością gospodarczą,
                </li>
                <li>
                  numer konta bankowego - wymagany tylko, gdy będziesz zwracał
                  lub reklamował towar.
                </li>
              </ul>
            </li>
            <li>
              Podanie przez Ciebie danych osobowych jest zawsze dobrowolne.
              Jednak jest niezbędne, abyś złożył zamówienie, zapisał się do
              Newslettera, zarezerwował i wypożyczył rower, akcesoria, lub
              skontaktował się z nami.
            </li>
            <li>
              Zapewniamy, że Twoje dane osobowe gromadzimy z należytą
              starannością i odpowiednio chronimy przed dostępem do nich osób
              nieupoważnionych. Gwarantujemy poufność i podjęcie wszelkich
              środków bezpieczeństwa i ochrony danych osobowych, których wymaga
              prawo RODO. Twoje dane osobowe przetwarzamy wyłącznie na
              terytorium Unii Europejskiej.
            </li>
          </ol>

          <h2 className="fs-5 my-3">
            III. W jakim celu zbieramy i przetwarzamy Twoje dane osobowe? Jak
            długo je przechowujemy? Jakie podstawy prawne nam to umożliwiają?
          </h2>
          <h3 className="fs-6 fw-bold">
            1. Zamówienia, rezerwacje, wypożyczenie roweru.
          </h3>

          <p className="text-justify">
            Gdy składasz zamówienie, musisz nam podać swoje dane osobowe
            niezbędne do realizacji zamówienia: imię i nazwisko, adres
            zamieszkania, adres e-mail, numer telefonu. Podczas rezerwacji
            możesz nam podać również swój przedział wagowy. Umożliwi nam to
            odpowiednie ustawienie amortyzatorów. Wypożyczając rower podajesz
            nam numery dwóch dowodów swojej tożsamości oraz nr PESEL. Możesz
            podać też dodatkowe dane dotyczące prowadzonej przez Ciebie
            działalności gospodarczej. Podstawą prawną do przetwarzania Twoich
            danych osobowych zawartych w zamówieniu jest Twoja zgoda na
            wykonanie umowy o świadczenie usług lub umowy najmu roweru. Podane
            przez Ciebie dane zostaną zapisane w bazie i będą w niej
            przechowywane do czasu przedawnienia roszczeń z tytułu zawartych
            umów. Każde zamówienie odnotowujemy jako odrębną pozycję w bazie.
            Oprócz danych osobowych w bazie przechowujemy również szczegóły
            zamówienia: datę złożenia zamówienia, przedmiot i wartość
            zamówienia.
          </p>
          <h3 className="fs-6 fw-bold">2. Newsletter</h3>
          <p className="text-justify">
            Za pośrednictwem strony{" "}
            <a href="http://bmsbike.com">http://bmsbike.com</a> możesz się
            zapisać do naszego Newslettera. Wówczas otrzymasz informacje o
            nowościach, promocjach, wyprzedażach, aktualnościach, produktach i
            usługach związanych z naszą aktywnością w Internecie. W celu
            przesłania Newslettera potrzebujemy wyłącznie Twojego adresu e-mail.
            Dodatkowo możesz też podać nam swoje imię. Podstawą prawną
            przetwarzania Twoich danych osobowych w przypadku Newslettera jest
            Twoja zgoda wyrażona poprzez kliknięcie w link aktywacyjny.
            Otrzymujesz go pocztą e-mail po zapisaniu się do Newslettera. Dane
            przetwarzane są przez czas funkcjonowania Newslettera. W każdej
            chwili możesz zrezygnować z subskrypcji Newslettera, klikając link
            anulujący subskrypcję, znajdujący się w stopce każdego Newslettera.
            Twoje dane nie są przez nas wykorzystywane do podejmowania decyzji
            opartych na zautomatyzowanym przetwarzaniu danych, które mogłyby
            mieć wpływ na Twoją sytuację prawną lub wywołać dla Ciebie inne
            podobne skutki. Nowoczesny system e-mail marketingu pozwala
            natomiast analizować działania odbiorców po otrzymaniu od nas
            wiadomości. Robimy to, by lepiej dostosować treść Newslettera do
            Twoich zainteresowań. W każdej chwili możesz sprzeciwić się takiemu
            przetwarzaniu danych.
          </p>

          <h3 className="fs-6 fw-bold">3. Poczta elektroniczna.</h3>
          <p className="text-justify">
            Gdy kontaktujesz się z nami poprzez pocztę elektroniczną, w sposób
            naturalny przekazujesz nam swój adres e-mail, jako nadawca
            wiadomości. Ponadto, w treści wiadomości możesz zawrzeć także inne
            dane osobowe. Podanie tych danych jest dobrowolne, ale może być
            konieczne, abyśmy mogli odpowiedzieć na Twoje zapytanie. W takim
            przypadku podstawą prawną do przetwarzania Twoich danych osobowych
            jest Twoja zgoda wynikająca z zainicjowania z nami kontaktu.
            Podstawą prawną przetwarzania danych po zakończeniu kontaktu jest
            uzasadniony interes w archiwizacji korespondencji na potrzeby
            wykazania jej przebiegu w przyszłości. Treść korespondencji może
            podlegać archiwizacji i nie jesteśmy w stanie jednoznacznie
            określić, kiedy zostanie usunięta. Masz prawo do domagania się
            przedstawienia historii korespondencji, którą z nami prowadziłeś
            (jeżeli była archiwizowana) oraz do domagania się jej usunięcia,
            chyba że jej archiwizacja jest uzasadniona z uwagi na nadrzędne
            interesy naszej firmy.
          </p>

          <h3 className="fs-6 fw-bold">4. Pliki cookies</h3>
          <p className="text-justify">
            Nasze strony internetowe, podobnie jak inne strony www, wykorzystują
            pliki cookies. Cookies są to małe pliki tekstowe zapisywane na Twoim
            urządzeniu końcowym, które mogą być odczytywane przez nasz system
            teleinformatyczny. Cookies umożliwiają nam m.in. prawidłowe
            działanie stron internetowych, poprawę szybkości i bezpieczeństwa
            korzystania ze stron, oraz korzystanie z funkcji społecznościowych.
            Podczas pierwszej wizyty na naszej stronie wyświetlana jest Ci
            informacja o stosowaniu cookies. Zawsze możesz zmienić ustawienia
            cookies w swojej przeglądarce lub usunąć je całkowicie.
          </p>

          <h3 className="fs-6 fw-bold">5. Kontakt telefoniczny</h3>
          <p className="text-justify">
            Gdy kontaktujesz się z nami telefonicznie w sprawach związanych z
            naszą działalnością, możesz przekazać nam swoje dane osobowe, które
            są niezbędne do rozwiązania Twojego zapytania. W trakcie rozmowy
            możemy zbierać takie dane jak: imię i nazwisko, numer telefonu,
            adres e-mail oraz inne informacje, które są konieczne do realizacji
            Twojej prośby.
          </p>


          <h3 className="fs-6 fw-bold">6. Reklamacje i zwroty</h3>
          <p className="text-justify">
            Gdy składasz reklamację lub zwrot towaru, musisz podać swoje dane
            osobowe niezbędne do przeprowadzenia tego procesu. Dane te obejmują
            imię i nazwisko, adres zamieszkania, adres e-mail, numer telefonu
            oraz numer konta bankowego w przypadku zwrotu środków. Przetwarzanie
            tych danych jest niezbędne do realizacji umowy sprzedaży oraz
            spełnienia obowiązków prawnych wynikających z przepisów o ochronie
            konsumentów. Dane te przechowujemy przez okres niezbędny do
            zakończenia procesu reklamacyjnego lub zwrotu towaru, a także przez
            okres wynikający z obowiązujących przepisów prawa dotyczących
            przechowywania dokumentacji księgowej.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
